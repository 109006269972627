import { range } from "lodash"
import { useState } from "react"

export function useForm(emptyForm, initialValues = {}) {
  const [values, setValues] = useState(() => ({
    ...emptyForm(),
    ...initialValues
  }))

  const reset = () => setValues(emptyForm())

  const update = updatePartial => {
    setValues({
      ...values,
      ...updatePartial
    })
  }

  const inputProps = key => ({
    value: values[key],
    onChange: value => {
      const updateValue = {
        [key]: value
      }
      update(updateValue)
    }
  })

  return {
    reset,
    update,
    values,
    inputProps
  }
}

export function useFormList(emptyEntry) {
  const [list, setList] = useState(() => [emptyEntry()])

  const reset = () => setList(() => [emptyEntry()])

  const update = (index, update) => {
    setList(
      list.map((entry, i) => {
        if (i == index) {
          return {
            ...entry,
            ...update
          }
        }
        return entry
      })
    )
  }

  const inputProps = (index, key) => ({
    value: list[index][key],
    onChange: value => update(index, { [key]: value })
  })

  const append = (amount = 1) => {
    const newEntries = range(amount).map(() => emptyEntry())
    setList([...list, ...newEntries])
  }

  const insert = insertion => {
    const newEntries = insertion.map(x => ({
      ...emptyEntry(),
      ...x
    }))

    setList([...list, ...newEntries])
  }

  const remove = index => {
    const newList = list.filter((_, i) => i != index)

    if (newList.length == 0) {
      setList([emptyEntry()])
    } else {
      setList(newList)
    }
  }

  return {
    list,
    reset,
    update,
    inputProps,
    append,
    remove,
    insert
  }
}
